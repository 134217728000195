import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { convertPropsToObject } from '../ContentRender';
import { ContentHtml, contentTextElements } from '../ContentText';

const TextBoxWrapper = styled('div')`
  margin: 2% auto 2%;
  width: 100%;
  .content-html-text {
    width: 100%;
    margin: 0 auto;
    ${contentTextElements} {
      padding-left: ${theme.general.pagePadding.sm};
      padding-right: ${theme.general.pagePadding.sm};
    }
    &.center {
      > ul {
        display: flex;
        width: 100%;
        margin: 0 auto;
        justify-content: space-around;
        flex-wrap: wrap;
        > li {
          margin: 15px;
          img {
            height: 25px;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  .inner {
    padding: 2rem 0;
    > div {
      max-width: 1000px;
      margin: 0 auto;
    }
  }
  &.primary .inner,
  &.secondary .inner {
    margin: 0 auto;
    padding: 2rem 0;
    ${theme.above.lg} {
      padding: 4rem 0;
    }
  }
  &.primary .inner {
    background: ${theme.startPage.row.background.primary};
    ${contentTextElements} {
      color: ${theme.startPage.row.color.primary};
    }
  }
  &.secondary .inner {
    background: ${theme.startPage.row.background.secondary};
    ${contentTextElements} {
      color: ${theme.startPage.row.color.secondary};
    }
  }
  &.max-width {
    max-width: ${theme.general.maxWidth};
    margin: 1rem auto;
    ${theme.above.lg} {
      padding: 0 1rem;
    }
  }
`;

const TextBox = ({ data, index }) => {
  const props = convertPropsToObject(data);
  const text = props.text.value;
  const textAlign = props.textAlign.value;
  const textColor = props.textColor.value;
  const background = props.background.value;
  const maxWidth = props.maxWidth && props.maxWidth.value === 'yes';
  return (
    <TextBoxWrapper
      className={`${background} ${maxWidth ? 'max-width' : ''}`}
      data-index={index}
    >
      <div className="inner">
        <ContentHtml
          className={`content-html-text ${textColor} ${textAlign}`}
          content={text}
        />
      </div>
    </TextBoxWrapper>
  );
};

export default TextBox;
