import React from 'react';
import { styled } from 'linaria/react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import Image from '@jetshop/ui/Image';
import { transparentDataImg } from '@jetshop/ui/Image/Image';
import { theme } from '../Theming/Theming';

const ItemWrapper = styled('div')`
  position: relative;
  color: inherit;
  text-align: center;
  > a {
    display: flex;
    flex-wrap: wrap;
    align-self: stretch;
    align-items: flex-start;
    flex-flow: column;
  }
  .item-image {
    padding: 0;
    width: 100%;
    .item-image-container {
      //padding-top: 55%;
      //background-size: cover;
    }
    img {
      max-width: 100%;
    }
  }
  .item-text {
    padding: 15px 0 30px;
    overflow: hidden;
    width: 100%;
  }
  .parent-name {
    margin-bottom: 0.5rem;
    h5 {
      font-weight: ${theme.weights.medium};
    }
  }
  .item-name {
    h3 {
      text-transform: none;
      font-weight: ${theme.weights.medium};
    }
    a {
      text-decoration: none;
    }
  }
  .item-content {
    margin-top: 0.5rem;
    max-height: 113px;
    overflow: hidden;
    img {
      display: none !important;
    }
    p {
      font-weight: 100;
      font-size: 0.87rem;
    }
  }
`;

const BlogItem = ({ category, index, className, showReadMore = false }) => {
  const getSizes = (index) => {
    let sizes = [1 / 2, 1 / 2, 1 / 2, 1 / 3];
    /*if (index === 0) {
      sizes = [1, 1, 1, 1200];
    }*/
    return sizes;
  };
  return (
    <ItemWrapper className={`blog-item ${className}`}>
      <CategoryLink category={category}>
        <div className="item-image">
          {category.images?.length ? (
            <Image
              className="item-image-container"
              src={category.images}
              alt={category.name}
              sizes={getSizes(index)}
              aspect={'1:1'}
              crop={true}
              cover
            />
          ) : (
            <Image src={transparentDataImg} aspect={'5:3'} />
          )}
        </div>
        <div className="item-text">
          {category.parent && (
            <div className="parent-name">
              <h5>{category.parent.name}</h5>
            </div>
          )}
          <div className="item-name">
            <h3>{category.name}</h3>
          </div>
        </div>
      </CategoryLink>
    </ItemWrapper>
  );
};

export default BlogItem;
