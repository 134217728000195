import { useQuery } from 'react-apollo';
import blogListQuery from './blogListQuery.gql';

export const useBlog = ({
  id,
  menu,
  pathname,
  limit = false,
  parentId = false,
  exclude = [],
}) => {
  const articleLevel = menu ? 3 : 2;
  const result = useQuery(blogListQuery, {
    variables: { root: id, levels: articleLevel },
  });

  const categories = result?.data?.categories || [];

  //MAIN BLOG CATEGORY
  const mainCategory = categories.length > 0 && categories[0];

  //MENU CATEGORIES
  const menuCategories = menu
    ? categories.filter((cat) => cat.level !== articleLevel)
    : false;

  //CURRENT MENU CATEGORY
  const currentCat =
    menu &&
    menuCategories.filter((cat) => cat.primaryRoute?.path === pathname)[0];
  const currentMenuCategory = currentCat ? currentCat : mainCategory;

  //ARTICLE CATEGORIES
  let articles = categories
    .filter((cat) => {
      if (!exclude.includes(cat.id)) {
        return parentId
          ? cat.parentId === parentId
          : cat.primaryRoute?.path?.search(pathname) !== -1 &&
              cat.level === articleLevel;
      }
      return false;
    })
    .map((cat) => {
      return {
        ...cat,
        parent:
          menu &&
          menuCategories.filter((parent) => parent.id === cat.parentId)[0],
      };
    });

  //ARTICLES SORTING
  articles.sort((a, b) => {
    const c = new Date(
      b.mainHeader?.length === 10 ? b.mainHeader : '2010-01-01'
    );
    const d = new Date(
      a.mainHeader?.length === 10 ? a.mainHeader : '2010-01-01'
    );
    return c - d;
  });

  //ARTICLES LIMIT
  if (limit > 0) articles = articles.slice(0, limit);

  return {
    mainCategory,
    menuCategories,
    currentMenuCategory,
    articles,
  };
};
